import React from 'react'
import { useState } from 'react'
import { Container, Wrapper, Title, Desc, CardContainer, ToggleButtonGroup, ToggleButton, Divider } from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'
import { projects } from '../../data/constants'


const Projects = ({openModal,setOpenModal}) => {
  const [toggle, setToggle] = useState('all');
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Research and Projects</Title>
        <Desc>
          I have worked on a wide range of aspect of software development from developing web apps, smarpthone apps and mini 2d games. Besides, I have published few research papers as well. Here are some of my projects and research papers.
        </Desc>
        <ToggleButtonGroup >
          {toggle === 'all' ?
            <ToggleButton active value="all" onClick={() => setToggle('all')}>All</ToggleButton>
            :
            <ToggleButton value="all" onClick={() => setToggle('all')}>All</ToggleButton>
          }
          <Divider />
          {toggle === 'projects' ?
            <ToggleButton active value="projects" onClick={() => setToggle('projects')}>PROJECTS</ToggleButton>
            :
            <ToggleButton value="projects" onClick={() => setToggle('projects')}>PROJECTS</ToggleButton>
          }
          <Divider />
          {toggle === 'games' ?
            <ToggleButton active value="games" onClick={() => setToggle('games')}>GAMES</ToggleButton>
            :
            <ToggleButton value="games" onClick={() => setToggle('games')}>GAMES</ToggleButton>
          }
          <Divider />
          {toggle === 'research_paper' ?
            <ToggleButton active value="research_paper" onClick={() => setToggle('research_paper')}>RESEARCH PAPER</ToggleButton>
            :
            <ToggleButton value="research_paper" onClick={() => setToggle('research_paper')}>RESEARCH PAPER</ToggleButton>
          }
          
        </ToggleButtonGroup>
        <CardContainer>
          {toggle === 'all' && projects
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
          {projects
            .filter((item) => item.category == toggle)
            .map((project) => (
              <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
            ))}
        </CardContainer>
      </Wrapper>
    </Container>
  )
}

export default Projects