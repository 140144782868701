import React from 'react'
import DPBgAnimation from '../DPBgAnimation'
import { DPContainer, DPBg, DPLeftContainer, Img, DPRightContainer, DPInnerContainer, TextLoop, Title, Span, SubTitle,SocialMediaIcons,SocialMediaIcon, ResumeButton } from './DPStyle'
import DPImg from '../../images/DisplayPicture.jpg'
import Typewriter from 'typewriter-effect';
import { Bio } from '../../data/constants';

const DPSection = () => {
    return (
        <div id="about">
            <DPContainer>
                <DPBg>
                    <DPBgAnimation />
                </DPBg>
                <DPInnerContainer >
                    <DPLeftContainer id="Left">
                        <Title>Hi ! </Title>
                        <TextLoop>
                            I am a
                            <Span>
                                <Typewriter
                                    options={{
                                        strings: Bio.roles,
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </Span>
                        </TextLoop>
                        <SubTitle>{Bio.description}</SubTitle>
                        <ResumeButton href={Bio.resume} target='display'>Resume</ResumeButton>
                    </DPLeftContainer>

                    <DPRightContainer id="Right">

                        <Img src={DPImg} alt="DP-image" />
                    </DPRightContainer>
                </DPInnerContainer>

            </DPContainer>
        </div>
    )
}

export default DPSection