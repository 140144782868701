import React from 'react'

export const darkTheme = {
    bg:"#12dbb2",
    bgLight: "#079174",
    primary:"#32d0b0",
    text_primary:"#242834",
    text_secondary:"#242834",
    card:"#eaf8f5",
    card_light: '#191924',
    button:"#03f7c5",
    white:"#FFFFFF",
    black:"#000000",
}

export const lightTheme = {
    bg:"#FFFFFF",
    bgLight: "#f0f0f0",
    primary:"#03f7c5",
    text_primary:"#111111",
    text_secondary:"#FFFFFF",
    card:"#FFFFFF",
    button:"#5c5b5b",
}